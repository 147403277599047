/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

import moment from 'moment';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "../../../MaterialKit/MKBox";
import MKTypography from "../../../MaterialKit/MKTypography";
import MKButton from "../../../MaterialKit/MKButton";

function NewsBlogCard({ image, title, date, description, articleContent, action, author, authorTitle, authorCompany }) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const renderContent = (content) => {
    return content.map((item, index) => {
      switch (item.type) {
        case 'bold-text':
          return (
            <div key={index} style={{ marginBottom: '10px', lineHeight: '1.6' }}>
              <strong>{item.text}</strong>
            </div>
          );
        case 'text':
          return (
            <div key={index} style={{ marginBottom: '10px', lineHeight: '1.6' }}>
              {item.text.split(urlRegex).map((part, partIndex) =>
                urlRegex.test(part) ? (
                  <MuiLink
                    key={partIndex}
                    href={part}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#3f51b5' }}
                  >
                    {part}
                  </MuiLink>
                ) : (
                  part
                )
              )}
            </div>
          );
        case 'bullet':
          return (
            <ul key={index} style={{ paddingLeft: '30px', marginBottom: '10px', lineHeight: '1.6' }}>
              {item.items.map((bullet, bulletIndex) => (
                <li key={bulletIndex}>
                  {bullet}
                </li>
              ))}
            </ul>
          );
        case 'number':
          return (
            <ol key={index} style={{ paddingLeft: '30px', marginBottom: '10px', lineHeight: '1.6' }}>
              {item.items.map((bullet, bulletIndex) => (
                <li key={bulletIndex}>
                  {bullet}
                </li>
              ))}
            </ol>
          );
        default:
          return null;
      }
    });
  };

  return (
    <Card>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="50%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          component="img"
          src={image}
          borderRadius="lg"
          shadow="md"
          width="70%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          zIndex={0}
          sx={{

            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={3} mt={-3} textAlign="left" height="100%">
        <MKTypography display="inline" variant="h4" textTransform="capitalize" fontWeight="regular">
          {title}
        </MKTypography>
        {date && <MKBox mt={1} mb={1} textAlign="right">
          <MKTypography variant="h6" color="dark">
            {moment.utc(date).local().format('DD MMM YYYY')}
          </MKTypography>
        </MKBox>}
        {author && <MKBox mt={1} mb={1} textAlign="right">
          <MKTypography variant="body2" color="dark">
            {author}
          </MKTypography>
          {authorTitle && <MKTypography variant="body2" color="dark">
            {authorTitle}
          </MKTypography>}
          {authorCompany && <MKTypography variant="body2" color="dark">
            {authorCompany}
          </MKTypography>}
        </MKBox>}
        {description && <MKBox mt={1} mb={3} textAlign="justify">
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>}
        {articleContent && <MKBox mt={1} mb={3} textAlign="justify">
          <MKTypography variant="body2" component="div">
            {renderContent(articleContent)}
          </MKTypography>
        </MKBox>}
      </MKBox>
      {action && <MKBox textAlign="right" m={2}>
        {action.type === "external" ? (
          <MKButton
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            size="small"
            color={action.color ? action.color : "dark"}
          >
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            component={Link}
            to={action.route}
            variant="gradient"
            size="small"
            color={action.color ? action.color : "dark"}
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>}
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
NewsBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default NewsBlogCard;
